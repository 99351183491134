<template>
  <div>
    <div class="content_area">
      <div class="headtitle">
        <div class="titleText">新建舱位咨询</div>
        <div>
          <el-button class="booking" type="success" size="small" @click="gobackFunc">返回运价
          </el-button>
          <el-button type="warning" size="small" :loading="loading" @click="onSubmit">保存
          </el-button>
        </div>
      </div>
      <template v-if="!hiddenOffer">
        <div class="title" >
          <div class="titleText">整箱运价信息</div>
        </div>
        <shipMsg :header="header" v-loading="loading"></shipMsg>

        <div class="cost_detail">
          <div v-for="(item, i) in  options" :key='i' v-if="item.reject == 0">
            <h1>{{ item.name + '成本价' }}</h1>
            <div>
              <span class="code">{{ item.code }}</span>
              <span class="num">{{ item.sell }}</span>
            </div>
          </div>
        </div>
      </template>

      <div class="title">
        <div class="titleText">订舱咨询信息</div>
      </div>
      <div>
        <el-form ref="agendaForm" :model="form" label-width="160px" :rules="rules">
          <div class="itemBox" v-if="hiddenOffer">
            <el-form-item class="f_item" label="起运港" prop="from_port">
              <portSelect
                v-model="form.from_port"
                placeTitle="请输入起运港"
                :width="'300px'"
                :size="'medium'"
                clearable
              />
            </el-form-item>
            <el-form-item class="f_item" label="目的港" prop="dest_port">
              <portSelect
                v-model="form.dest_port"
                placeTitle="请输入目的港"
                :width="'300px'"
                :size="'medium'"
                clearable
              />
            </el-form-item>
          </div>
          <el-form-item label="箱货信息" style="display:inline-block">
            <div class="boxtype" v-for="(item,index) in checkedArr" :key="index">
              <span>{{item.name}}</span>
              <el-input-number size="medium"
                @keydown.native="channelInputLimit"
                v-model="item.quantity" :min="0">
              </el-input-number>
            </div>
            
            <div style="color: #999999; padding-left: 50px">(请至少设置一种箱型的数量)</div>
          </el-form-item>
          <div class="itemBox">
            <el-form-item class="f_item" label="UNNO">
              <unnoSelect v-model="un_no" @unnoSelectFunc="unnoSelectFunc($event)"  :width="'300px'" 
              :dangerous_id="dangerous_id"
                :clearable="true" :placeTitle="'请输入UNNO'" :size="'medium'"></unnoSelect>
            </el-form-item>
            <el-form-item class="f_item" label="Class：">
              <span>{{ form.classify }}</span>
              <span v-show="!form.classify" style="color: #999999; ">请先录入UNNO</span>
            </el-form-item>
          </div>
          <div class="itemBox">
            <el-form-item class="f_item" label="PG：">
              <span>{{ form.pg }}</span>
              <span v-show="!form.pg" style="color: #999999; ">请先录入UNNO</span>
            </el-form-item>
            <el-form-item class="f_item" label="PSN：">
              <span>{{ form.psn }}</span>
              <span v-show="!form.psn" style="color: #999999; ">请先录入UNNO</span>
            </el-form-item>
          </div>
          <div class="itemBox">
            <el-form-item class="f_item" label="开航日期" prop="etd">
              <div style="display: flex; justify-content: space-between">
                <el-date-picker style="width: 300px" v-model="form.etd" type="date" placeholder="选择日期">
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item class="f_item" label="货重" prop="cargo_weight">
              <el-input v-model="form.cargo_weight" style="width:300px"
                oninput="value=value.replace(/^\D*([0-9]\d*\.?\d{0,3})?.*$/, '$1')">
                <template slot="append">KGS</template>
              </el-input>
            </el-form-item>
          </div>
          <div class="itemBox">
            <el-form-item class="f_item" label="订舱客户" prop="booking_customer">
              <el-autocomplete 
                style="width:300px"
                v-model="form.booking_customer"
                :fetch-suggestions="querySearch"
                placeholder="请输入订舱客户"
                @select="handleSelect"
                @blur="customerChange"
              ></el-autocomplete>
            </el-form-item>
            <el-form-item class="f_item" label="客户公司" prop="booking_customer_company">
              <el-input v-model="form.booking_customer_company" style="width:300px" :disabled="customer_company_dis">
              </el-input>
            </el-form-item>
          </div>
          <el-form-item label="询价商务" prop="business_id">
            <el-select v-model="form.business_id" placeholder="请选择询价商务" filterable style="width:300px">
              <el-option 
                v-for="item in affairsOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.remarks" maxlength="300" show-word-limit rows="3"></el-input>
          </el-form-item>
          <el-form-item></el-form-item>
        </el-form>
      </div>

    </div>
  </div>
</template>

<script>
import shipMsg from "~bac/components/ymp/shipMsg";
import portSelect from "~ymp/components/common/portSelect";
import unnoSelect from "~ymp/components/common/unnoById";
export default {
  name: "consulting",
  components: {
    shipMsg,
    portSelect,
    unnoSelect
  },
  data() {
    return {
      options: [
        {
          checked: false,
          name: "20GP",
        },
        {
          checked: false,
          name: "40GP",
        },
        {
          checked: false,
          name: "40HQ",
        },
      ],
      checkedArr: [{
        'name': '20GP',
        'quantity': 1
      },{
        'name': '40GP',
        'quantity': 0
      },{
        'name': '40HQ',
        'quantity': 0
      },],
      un_no:'', //单独存储
      dangerous_id:'', 
      form: {
        un_no: '',
        classify: '',
        pg: '',
        psn: '',
        etd: '',
        cargo_weight: '', //货重
        remarks: '',
        business_id:'', // 询价商务
        booking_customer:'', // 订舱客户
        booking_user_id: 0, // 订舱客户ID
        booking_customer_company:'' , // 客户公司
        booking_company_id: 0, // 客户公司ID
      },
      rules: {
        un_no: [{ required: true, message: " ", trigger: "change" }],
        etd: [{ required: true, message: " ", trigger: "change" }],
        business_id: [{ required: true, message: " ", trigger: "change" }],
        cargo_weight: [{ required: true, message: " ", trigger: "change" }],
        booking_customer: [{ required: true, message: " ", trigger: "change" }],
        booking_customer_company: [{ required: true, message: " ", trigger: "change" }],
        from_port: [{ required: true, message: " ", trigger: "change" }],
        dest_port: [{ required: true, message: " ", trigger: "change" }],
      },
      header: {},
      affairsOptions:[],
      loading: false,
      hiddenOffer: false,
      volume: 0,
      weight: 1000,
      seaTotal: 0,
      unno: "",
      user_type: 1,
      customer_company_dis: true
    };
  },
  watch:{
    "checkedArr": {
      deep: true,
      handler(nv) {
        // console.log(nv);
      },
    },
  },
  mounted() {
    this.getaffairsOptions();
    this.user_type = this.USER_INFO ? this.USER_INFO.user_type : 1;
    if (this.$route.query.unno) {
      this.unno = this.$route.query.unno;
      this.un_no = this.$route.query.unno;
      this.handleInitValue(this.$route.query.unno);
    }
    this.getHeader();
  },
  computed: {},
  methods: {
    async handleInitValue(p_un_no) {
      let parmas = {
        un_no: p_un_no
      };
      this.$store.dispatch("API_bookingSpace/getUnnoList", parmas).then((res) => {
        this.loading = false;
        if (res.success) {
          let unnoList = res.data;
          if(unnoList && unnoList.length){
            this.dangerous_id = unnoList[0].id;
          }
        }
      });
    },
    async querySearch(queryString, cb) {
      if(!queryString){
        cb([]);
        return false;
      }
      try {
        let params = {
          user_id:this.USER_INFO.id,
          nopage:1,
          name: queryString
        };
        let data = await this.$store.dispatch( //
          "API_freight/cooperative_client_list",
          params
        );
        if (data.success) {
            data.data.forEach((ee) => {
              ee.value =
                this.LOCALE == "en"? ee.name_en? ee.name_en: ee.name_zh: ee.name_zh? ee.name_zh: ee.name_en;
            });
            cb(data.data);
          }
        } catch (e){
          cb([]);
          return false;
      }
    },
    customerChange(){
      if(this.form.booking_customer){
        this.form.booking_customer_company = "";
        this.form.booking_user_id = 0;
        this.form.booking_company_id = 0;
        this.customer_company_dis = false;
      }
    },
    handleSelect(item) {
      if(item && item.company_name_zh){
        this.customer_company_dis = true;
        this.form.booking_user_id = item.id;
        this.form.booking_company_id = item.company_id;
        this.form.booking_customer_company = item.company_name_zh
      }else{
        this.form.booking_customer_company = "";
        this.customer_company_dis = false;
        this.form.booking_user_id = 0;
        this.form.booking_company_id = 0;
      }
    },
    unnoSelectFunc(e) {
      if (e) {
        this.form.pg = e.packing_group;
        this.form.classify = e.classify;
        this.form.psn = e.pscn;
        this.form.un_no = e.un_no
      } else {
        this.form.pg = '';
        this.form.classify = '';
        this.form.psn = '';
        this.form.un_no = '';
      }
    },
    channelInputLimit(e) {
      e.returnValue = '';
    },
    checkChange() { // 校验数量大于 0 的
      this.options.forEach((res) => {
        let check_item = this.checkedArr.find(item => {
          return (item.quantity > 0 && res.name == item.name)
        })
        if (check_item && check_item.name == res.name) {
          res.checked = true;
          res.quantity = check_item.quantity;
        } else {
          res.checked = false;
        }
      });
    },
    gobackFunc(){
      if(this.$route.query.copy_id){
        this.$router.push({
          path: "/freightSearch",
        });
      }else{
        this.$router.go(-1);
      }
    },
     onSubmit() {
      let sum = 0, container_info = [];
      this.checkedArr.forEach(item=>{
        sum += Number(item.quantity)
        if(Number(item.quantity) > 0){
          container_info.push(item)
        }
      })
      if(sum <= 0){
        this.$message.warning("请至少设置一种箱型的数量");
        return false;
      }
      this.$refs["agendaForm"].validate(async(valid) => {
        if (valid) {
          let sum = 0;
          this.checkedArr.forEach(item=>{
            sum += Number(item.quantity)
          })
          if(sum <= 0){
            this.$message.warning("请至少设置一种箱型的数量");
            return false;
          }
          let params = Object.assign({
            sales_id: this.USER_INFO.id,
            container_info: JSON.stringify(container_info),
          }, this.form);
          if(params.etd){
            params.etd = this.$moment(params.etd).unix()
          }
          if(this.header && JSON.stringify(this.header) != '{}'){
            params.terminal = this.header.affiliated_terminal || '';
            params.shipping_date = this.header.shipping_date || '';
            params.validity_start = this.header.validity_start || '';
            params.validity_end = this.header.validity_end || '';
            params.from_port = this.header.from_port_id || '';
            params.dest_port = this.header.dest_port_id || '';
            params.freight_remarks = this.header.remarks || ''; // 运价备注
            params.freight_id	= this.header.id || '';
            params.shipping_company	= this.header.shipping_company || '';
          }

          this.checkChange();
          if(this.options){
            params.sea_freight = JSON.stringify((this.options.filter((item=>{
              return item.checked == true;
            }))).map(item=>{
              let obj = {
                  name: item.name,
                  price: item.price || 0,
                  currency: item.code || 'USD',
                  quantity: item.quantity || 1,
                }
                return obj;
            }))
          }

          this.loading = true;
          let res = await this.$store.dispatch("API_bookingSpace/addShippingConsultings", params);
          this.loading = false;
          if(res.success){
            this.$confirm('咨询成功，是否前往舱位咨询?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$router.push({
              path: "/console/consulting",
              query: {},
            });
            }).catch((action) => {
              if(action == 'cancel'){
                this.$router.go(-1);
              }
            });
          }
        }
      });
    },
    async fillData(){
      this.loading = true;
      let params = {
        id: this.$route.query.copy_id,
        sales_id: this.USER_INFO.id
      }

      let data = await this.$store.dispatch(
        "baseConsole/shippingSpaceDetail",
        params
      )
      if (data.success) {
        this.loading = false;
        let pageDetail = JSON.parse(JSON.stringify(data.data));
        this.hiddenOffer = true;
        try{
          pageDetail.container_info = JSON.parse(pageDetail.container_info);
          this.checkedArr = pageDetail.container_info;
        }catch(e){
          console.log(e)
        }
        
        this.$nextTick(()=>{
          this.un_no = pageDetail.un_no || '', //单独存储
          this.handleInitValue(pageDetail.un_no);
          this.form = {
            from_port: pageDetail.from_port,
            dest_port: pageDetail.dest_port,
            un_no: pageDetail.un_no || '',
            classify: pageDetail.classify || '',
            pg: pageDetail.pg || '',
            psn: pageDetail.psn || '',
            etd: pageDetail.etd ? pageDetail.etd * 1000 : null,
            cargo_weight: pageDetail.cargo_weight || '',
            remarks: pageDetail.remarks || '',
            business_id: pageDetail.business_id || '',
            booking_customer: pageDetail.booking_customer || '',
            booking_customer_company: pageDetail.booking_customer_company || '',
          }
          
          
          this.options.map(item => {
            pageDetail.sea_freight.forEach(i=>{
              if (item.name == i.name) {
                item.price = i.price;
                item.code = i.currency;
                item.checked = true
              }
            })
            return item
          })
        })
      } else {
        this.loading = false;
        this.$message.error('操作失败')
      }
    },
    getaffairsOptions() {
      let params = {
        role_name: '商务',
        nopage: 1,
      }
      this.$store.dispatch("API_bookingSpace/getossUserList", params).then((res) => {
        this.affairsOptions = res.data;
      });
    },
    async getHeader() {
      if(this.$route.query.copy_id){
        this.fillData();
        return;
      }
      if(!this.$route.query.id){
        this.options[0].checked = true;
        // this.checkedArr = ['20GP'];
        this.hiddenOffer = true;
        return;
      }
      this.loading = true;
      let params = {
        freight_id: this.$route.query.id,
        user_type: this.USER_INFO ? this.USER_INFO.user_type : 1,
        unno: this.unno,
        dangerous_status: this.unno ? 1 : 0,
      };

      let result = await Promise.all([
        this.$store.dispatch("API_freight/getFclInfoDetail", params),

      ]);
      this.loading = false;
      if (result[0].success) {
        let data = result[0].data;
        data.week = [];
        data.abs = Math.abs(data.price_lcl_dangerous);
        data.shipping_date_arr = data.shipping_date.split(",");
        data.shipping_date_arr.forEach((ee) => {
          if (ee == 1) {
            data.week.push("周一");
          }
          if (ee == 2) {
            data.week.push("周二");
          }
          if (ee == 3) {
            data.week.push("周三");
          }
          if (ee == 4) {
            data.week.push("周四");
          }
          if (ee == 5) {
            data.week.push("周五");
          }
          if (ee == 6) {
            data.week.push("周六");
          }
          if (ee == 7) {
            data.week.push("周日");
          }
        });
        // 后台用身份去计算价格
        this.options[0].price = data.price_20gp.toFixed();
        this.options[0].sell = data.price_20gp.toFixed(); 
        // this.options[0].sell = data.price_20gp_of_sell.toFixed();
        this.options[0].reject = data.reject_20gp.toFixed();

        this.options[1].price = data.price_40gp.toFixed();
        this.options[1].sell = data.price_40gp.toFixed();
        // this.options[1].sell = data.price_40gp_of_sell.toFixed();
        this.options[1].reject = data.reject_40gp.toFixed();

        this.options[2].price = data.price_40hc.toFixed();
        this.options[2].sell = data.price_40hc.toFixed(); 
        // this.options[2].sell = data.price_40hc_of_sell.toFixed();
        this.options[2].reject = data.reject_40hc.toFixed();

        this.options.map(item => {
          item._price = item.price
          item.is_edit = false
          item.code = data.currency_code
          return item
        })
        let check_item = this.options.find(item => {
          return item.reject == 0
        })
        this.options.map(item => {
          if (item.name == check_item.name) {
            item.checked = true
          }
          return item
        })

        // if (this.checkedArr.length == 0 && check_item) {
        //   this.checkedArr.push(check_item.name)
        // }

        this.seaTotal = data.price_20gp;
        this.volume = data.mini_cargo;
        this.header = data;
      }
    },
  },
};
</script>

<style scoped lang="less">
.cost_detail {
  background: #fff;
  margin-top: 10px;
  height: 118px;
  padding: 19px 0;
  display: flex;
  justify-content: space-around;

  >div {
    width: 25%;
    border-left: 1px solid #DCDFE6;
    text-align: center;

    h1 {
      padding-bottom: 10px;
      height: 33px;
      font-size: 18px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      line-height: 33px;
    }

    .code,
    .num {
      font-size: 18px;
      color: #FF7727;
    }

    .num {
      display: inline-block;
      padding-left: 5px;
      font-size: 24px;
    }
  }

  :first-child {
    border-left: 0;
  }
}

.nodata {
  height: 100px;
  text-align: center;
  line-height: 100px;
  color: #606266;
}

.page_tips {
  width: 1300px;
  margin: auto;
  padding: 26px 0;
  display: flex;
  align-items: center;

  i {
    color: #FAAD14;
    font-size: 30px;
    padding-right: 9px;
  }

  span {
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}

.unno {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.mohu {
  color: transparent;
  -moz-user-select: none; //火狐
  -webkit-user-select: none; //webkit浏览器（Chrome，Opera，Safari等）
  -ms-user-select: none; //IE10
  -khtml-user-select: none; //早期浏览器
  user-select: none;
  text-shadow: #000 0 0 8px;
}

.moneyList {
  background: #fff;
  padding: 24px;

  .seaItem {
    margin-bottom: 30px;

    .itemTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .btn {
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
    }

    .itemTitleBox {
      padding-bottom: 20px;
    }

    .el-icon-ship {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: #ff7727;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      color: #fff;
      margin-right: 10px;
    }

    .itemTitle {
      font-weight: 900;
      font-size: 18px;
    }

    .itemMOney {
      font-weight: 900;
      font-size: 18px;
    }

    .itemTips {
      font-size: 16px;
      color: #ff7727;
      padding-left: 10px;
    }

    .tableLine {
      display: flex;
      align-items: center;
      padding: 13px 0;
    }

    .tableLine {
      min-height: 60px;
      border-bottom: 1px solid #EBEEF5;

      .table1 {
        width: 250px;
      }

      .table2 {
        width: 220px;
      }

      .table3 {
        width: 220px;
      }

      .table4 {
        width: 220px;
      }

      .table5 {
        width: 220px;
      }

      .table6 {
        padding: 0;
        max-height: 40px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }

    .tableTitle {
      background: #fbfcfa;
      border-bottom: 1px solid #ebeef5;
    }
  }
}

.seaMoney {
  background: #fff;
  padding: 20px 0;

  margin: 20px 0;

  .seaTitle {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    padding-left: 24px;
    font-size: 18px;
    font-weight: 900;
  }

  .searchBox {
    margin-top: 20px;
    padding-left: 24px;
    display: flex;
    align-items: center;

    .flex {
      display: flex;
      align-items: center;
    }
  }
}

.chooseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .itemtitle {
    font-size: 14px;
  }

  .switch {
    height: 50px;
  }
}

.booking {
  background-color: #91be42;
  border-color: #91be42;
}

.content_area {
  min-height: 83vh;
}

.title,
.headtitle {
  display: flex;
  height: 88px;
  align-items: center;
  justify-content: space-between;

  .titleText {
    width: 145px;
    height: 43px;
    background: #8DC12E;
    border-radius: 10px 0px 0px 0px;
    text-align: center;
    line-height: 44px;
    color: white;
    font-size: 16px;
  }

  .booking {
    background-color: #91be42;
    border-color: #91be42;
  }
}

.headtitle {
  .titleText {
    background: none;
    color: #1f292e;
    font-weight: 800;
    text-align: left;
  }
}

::v-deep .el-checkbox {
  .el-checkbox__label {
    color: #91be42;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #91be42;
    border-color: #91be42;
  }

  .el-checkbox__input.is-focus .el-checkbox__inner,
  .el-checkbox__inner:hover {
    border-color: #91be42;
  }
}

::v-deep .el-form-item__content {
  display: flex;
}
.boxtype{
  span{
    margin-right: 10px;
  }
  .el-input-number--medium{
    width: 160px;
    margin-right: 30px;
  }
}

.itemBox {
  display: flex;

  .f_item {
    width: 50%;
    font-size: 14px;
    line-height: 32px;
  }
}
</style>
